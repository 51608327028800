import { template as template_18516b82d64b4242a56b2195a0b92033 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_18516b82d64b4242a56b2195a0b92033(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
