import { template as template_1403f290e58c447fac963547a20e4b9f } from "@ember/template-compiler";
const FKControlMenuContainer = template_1403f290e58c447fac963547a20e4b9f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
