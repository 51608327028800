import { template as template_a1049ec52de0441c93962201f2c4c33b } from "@ember/template-compiler";
const FKLabel = template_a1049ec52de0441c93962201f2c4c33b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
