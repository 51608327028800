import { template as template_de659a9d2abb4e76ac25574397fe5b36 } from "@ember/template-compiler";
const WelcomeHeader = template_de659a9d2abb4e76ac25574397fe5b36(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
